import { createGlobalStyle } from "styled-components"
import tw from "twin.macro"

const GlobalStyle = createGlobalStyle`
  body {
    ${tw`font-body`}
  }

  .demand360-brand {
    font-family: "Axiforma ExtraBold", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .demand360-brand-vertical {
    font-family: "Axiforma ExtraBold", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
  }

  @keyframes typetura-h1 {
    0%, 23.4375% {
      font-size: 2.5rem;
      line-height: 1.1;
    }
    40% {
      font-size: 3.125rem;
      line-height: 1.1;
    }
    75%, 100% {
      font-size: 3.75rem;
      line-height: 1.1;
    }
  }

  @keyframes typetura-h2 {
    0%, 23.4375% {
      font-size: 1.875rem;
      line-height: 1.25;
    }
    40% {
      font-size: 2.375rem;
      line-height: 1.25;
    }
    75%, 100% {
      font-size: 2.75rem;
      line-height: 1.25;
    }
  }

  @keyframes typetura-h3 {
    0%, 23.4375% {
      font-size: 1.5rem;
      line-height: 1.35;
    }
    75%, 100% {
      font-size: 2rem;
      line-height: 1.25;
    }
  }

  @keyframes typetura-h4 {
    0%, 23.4375% {
      font-size: 1.125rem;
      line-height: 1.35;
    }
    75%, 100% {
      font-size: 1.625rem;
      line-height: 1.35;
    }
  }

  @keyframes typetura-h5 {
    0%, 23.4375% {
      font-size: 1rem;
      line-height: 1.35;
    }
    75%, 100% {
      font-size: 1.375rem;
      line-height: 1.35;
    }
  }

  @keyframes typetura-p {
    0%, 23.4375% {
      font-size: 0.875rem;
      line-height: 1.75;
    }
    26.75% {
      font-size: 0.9375rem;
      line-height: 1.75;
    }
    50%, 100% {
      font-size: 1rem;
      line-height: 1.75;
    }
  }

  @keyframes typetura-p-small {
    0%, 23.4375% {
      font-size: 0.8125rem;
      line-height: 1.75;
    }
    26.75% {
      font-size: 0.8125rem;
      line-height: 1.75;
    }
    50%, 100% {
      font-size: 0.8125rem;
      line-height: 1.75;
    }
  }

  @keyframes typetura-p-large {
    0%, 23.4375% {
      font-size: 1.125rem;
      line-height: 1.5;
    }
    50%, 100% {
      font-size: 1.25rem;
      line-height: 1.5;
    }
  }

  .typetura, .typetura-article {
    ${tw`font-body`}
  }

  .typetura h1 {
    --tt-key: typetura-h1;
    font-weight: bold;
  }

  .typetura h2, .typetura-article h1 {
    --tt-key: typetura-h2;
    font-weight: bold;
  }

  .typetura h3, .typetura-article h2 {
    --tt-key: typetura-h3;
    font-weight: bold;
  }

  .typetura h4, .typetura-article h3 {
    --tt-key: typetura-h4;
    font-weight: bold;
  }

  .typetura h5,
  .typetura-article h4,
  .typetura-article h5 {
    --tt-key: typetura-h5;
    font-weight: bold;
  }

  .typetura p,
  .typetura-article,
  .typetura-article p {
    --tt-key: typetura-p;
  }

  .typetura p.small {
    --tt-key: typetura-p-small;
  }

  .typetura p.large {
    --tt-key: typetura-p-large;
  }
`

export default GlobalStyle
