import React, { FC } from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface SocialShareImages {
  social?: string;
  twitter?: string;
}

interface Props {
  title: string;
  path: string;
  description?: string;
  lang?: string;
  meta?: any[];
  shareImages?: SocialShareImages;
  useTitleTemplate?: boolean;
}

const defaultShareImages: SocialShareImages = {
  social:
    "https://media.graphassets.com/output=format:jpg/6YInt81QYKXp5s478gDj",
  twitter:
    "https://media.graphassets.com/output=format:jpg/6YInt81QYKXp5s478gDj",
};

const Seo: FC<Props> = ({
  description,
  lang = "en",
  meta = [],
  title,
  path,
  shareImages = {},
  useTitleTemplate = true,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const defaultMeta = (
    !meta.find((m) => m.property === "og:type")
      ? [
          {
            property: `og:type`,
            content: `website`,
          },
        ]
      : []
  ).concat(
    path
      ? [
          {
            property: `og:url`,
            content: `${site.siteMetadata.siteUrl}${path}`,
          },
        ]
      : []
  );

  const metaImages = [
    {
      property: "og:image",
      content: shareImages.social || defaultShareImages.social,
    },
    {
      name: "twitter:image",
      content: shareImages.twitter || defaultShareImages.twitter,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={useTitleTemplate ? `%s | ${site.siteMetadata.title}` : "%s"}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:locale`,
          content: "en_US",
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:site`,
          content: "@ParrotAnalytics",
        },
      ]
        .concat(meta)
        .concat(defaultMeta)
        .concat(metaImages)}
    />
  );
};

export default Seo;
