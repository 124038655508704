import * as React from "react";
import tw, { styled } from "twin.macro";
import { motion, useTransform, useViewportScroll } from "framer-motion";

import Layout from "../components/layout";
import Seo from "../components/seo";
import LogoStacked from "../images/logo-stacked.svg";

const Header = styled.div`
  ${tw`relative overflow-hidden h-screen w-full`}

  .header-image {
    ${tw`relative w-full h-full`}

    transform: scale(1.1);
    animation: zoomout 20s forwards;

    @keyframes zoomout {
      0% {
        transform: scale(1.1);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
      }
    }

    figure {
      ${tw`absolute top-0 left-0 w-full h-full m-0`}
    }

    .signs-final {
      ${tw`opacity-0`}

      animation: signs-final-fade 20s forwards;
    }

    @keyframes signs-final-fade {
      0% {
        opacity: 0;
      }
      70% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .signs {
      ${tw`opacity-0`}

      mask-image: radial-gradient(circle, #fff 0%, transparent 20%);
      mask-size: 100% 100%;
      mask-position: center center;
      mask-repeat: no-repeat;
      animation: clip-fade 20s forwards;
    }

    @keyframes clip-fade {
      0% {
        opacity: 0;
      }
      5% {
        opacity: 0;
        mask-size: 100% 100%;
      }
      10% {
        opacity: 1;
      }
      100% {
        mask-size: 2000% 2000%;
        opacity: 1;
      }
    }

    .city {
      ${tw`opacity-50`}

      animation: city 20s forwards;
    }

    @keyframes city {
      0% {
        opacity: 0.5;
      }
      30% {
        opacity: 1;
      }
    }

    img {
      ${tw`w-full h-full object-cover`}

      object-fit: cover;
    }

    .overlay {
      ${tw`relative h-full w-full`}

      background: linear-gradient(
        180deg, rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 33%,
        rgba(0, 0, 0, 0.6) 66%,
        #000000 100%
      );
    }
  }

  .logo {
    ${tw`absolute flex flex-col h-full`}

    z-index: 1;
    left: 0;
    right: 0;

    top: calc(50% - 75px);

    @media (min-width: 640px) {
      top: calc(50% - 95px);
    }

    @media (min-width: 1024px) {
      top: calc(50% - 115px);
    }

    img {
      ${tw`mx-auto`}

      width: 150px;

      @media (min-width: 640px) {
        width: 190px;
      }

      @media (min-width: 1024px) {
        width: 230px;
      }
    }

    h1 {
      ${tw`text-center uppercase font-extrabold text-yellow mt-6 l:mt-10 text-xl m:text-2xl l:text-3xl`}

      line-height: 33px;
      letter-spacing: 5px;

      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
        0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1),
        0px 4px 4px rgba(0, 0, 0, 0.1), 0px 8px 8px rgba(0, 0, 0, 0.1);

      @media (min-width: 640px) {
        line-height: 42px;
        letter-spacing: 7px;
      }

      @media (min-width: 1024px) {
        line-height: 50px;
        letter-spacing: 10px;
      }

      br {
        @media (min-width: 1024px) {
          ${tw`hidden`}
        }
      }
    }

    .mouse-scroll {
      ${tw`hidden m:block mx-auto mt-16 flex-grow relative`}

      img {
        ${tw`opacity-50`}

        height: 59px;
        width: 20px;
      }
    }
  }
`;

const header = {
  end: "https://media.graphcms.com/resize=w:2880/auto_image/cache=expiry:max/no_metadata/fCyIJUFQSYxyoszUkZNx",
  start:
    "https://media.graphcms.com/resize=w:1920/compress/auto_image/cache=expiry:max/no_metadata/btk9dTGQ32ZYhMEDR6bx",
  title: "Welcome to\nthe attention economy",
  logoLink: "https://www.parrotanalytics.com/",
};

const IndexPage = () => {
  let { start, end, title, logoLink } = header;

  title = title.replace("\n", "&nbsp;<br />");

  // staggered fade in animations
  const headerList = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 1,
        duration: 0,
        delay: 0,
        ease: "easeOut",
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
        duration: 1.5,
      },
    },
  };

  const headerItem = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1.5, ease: "easeOut" },
    },
    hidden: { opacity: 0, y: 30 },
  };

  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 500], [0, -160]);
  const y2 = useTransform(scrollY, [0, 500], [0, -120]);

  return (
    <Layout>
      <Seo
        title="Parrot Analytics: Leading global audience demand analytics across TV, Movies and Talent"
        path="/"
        useTitleTemplate={false}
      />
      <Header>
        <div className="header-image">
          <figure className="city">
            <img src={start} alt="" />
          </figure>
          <figure className="signs">
            <img src={end} alt="" />
          </figure>
          <figure className="signs-final">
            <img src={end} alt="" />
          </figure>
          <div className="overlay" />
        </div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={headerList}
          className="logo"
        >
          <motion.a href={logoLink} variants={headerItem} style={{ y: y1 }}>
            <motion.img src={LogoStacked} alt="logo" />
          </motion.a>
          <motion.h1
            variants={headerItem}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            style={{ y: y2 }}
          />
        </motion.div>
      </Header>
    </Layout>
  );
};

export default IndexPage;
