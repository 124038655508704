import React, { FC } from "react";
import Helmet from "react-helmet";

import GlobalStyle from "./global-style";

const Layout: FC = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Helmet
        bodyAttributes={{
          class: "bg-black",
        }}
      />
      {children}
    </>
  );
};

export default Layout;
